
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        














































































































































.affiliate-reporting-table {
  position: relative;
  width: 100%;

  &__column--disabled {
    pointer-events: none;
  }

  &__item--action {
    color: $--clb-color-primary;
    cursor: pointer;
    text-decoration: underline;
  }

  &.el-table,
  .el-table,
  .el-table__fixed,
  &.el-table__fixed {
    &::before {
      display: none;
    }

    &__fixed {
      box-shadow: none;

      &-header-wrapper,
      &-body-wrapper {
        z-index: auto;
      }
    }

    &__empty-block {
      width: auto !important;
    }

    .el-table__body-wrapper {
      overscroll-behavior-x: none;
    }

    .cell {
      font-size: $--clb-font-size-xs;
      line-height: $--clb-font-size-sm;
      overflow: hidden;
      text-overflow: ellipsis;

      .skeleton-box {
        display: block;
      }
    }

    td,
    th {
      color: $--clb-body-font;

      &:first-child {
        text-align: left;
      }
    }

    thead {
      padding: $--clb-space-4;

      th {
        padding: 6px 0;

        .cell {
          font-weight: 700;
          white-space: nowrap;
          padding: 0 18px 0 0;
          position: relative;
          overflow: visible;
          overflow-x: hidden;
          line-height: 34px;

          &:first-child {
            padding-left: $--clb-space-2;

            .caret-wrapper {
              position: absolute;
              right: 0;
            }
          }
        }
      }

      th:not(:first-child) {
        .cell {
          padding-right: 18px;
        }

        .caret-wrapper {
          width: 18px;
          margin-left: 4px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    .el-table__body {
      tbody {
        tr {
          transition: 0.3s ease;
          cursor: pointer;

          td:first-child .cell {
            color: #262626;
            font-weight: 700;
          }

          .cell {
            padding: 0 $--clb-space-2;
          }
        }
      }
    }

    .el-table__footer-wrapper tbody td {
      background: $--clb-color-accent__dark;
      color: $--border-color-base;
      font-weight: 700;

      .cell {
        padding: 0 $--clb-space-2;
      }
    }
  }

  &__pagination {
    margin-top: $--clb-space-1;
    padding: 0;

    .el-pager {
      li.active {
        color: $--clb-color-accent__dark;
      }
    }
  }
}

.app-header__portal,
.app-right-sidebar__header--portal {
  .affiliate-reporting-table {
    position: relative;
    width: 100%;
    padding: 0 $--clb-mobile-padding;

    th.is-leaf,
    td {
      border-bottom: none;
      background: $--clb-color-primary__white;
    }
  }
}

@media (min-width: $--sm) {
  .app-header__portal {
    .affiliate-reporting-table {
      padding: 0 $--clb-layout-4 0 $--clb-layout-4;
      margin: 0 auto;

      .el-table__fixed {
        left: $--clb-layout-4;
      }
    }
  }

  .app-right-sidebar__header--portal {
    .affiliate-reporting-table {
      padding: 0 $--clb-layout-2;
    }
  }
}

@media (min-width: $--md) {
  .app-header__portal {
    .affiliate-reporting-table {
      padding: 0 $--clb-layout-2 + $--clb-mobile-padding;
    }
  }
}

@media (min-width: $--lg) {
  .app-header__portal {
    .affiliate-reporting-table {
      padding: 0 $--clb-layout-4 0 $--clb-layout-4;
    }
  }
}
