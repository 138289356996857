
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        









































.affiliate-reporting-export-button {
  height: 50px;
  font-size: $--clb-font-size-sm;

  span {
    line-height: 15px;
  }
}
